<template>
  <div id="goods-details">
    <breadcrumb />
    <div class="goods-details-con">
      <div class="top">
        <div class="commodity-img-box">
          <pic-zoom :url="info" :scale="2" class="commodity-img"></pic-zoom>
          <div class="commodity-img-list">
            <img
              src="@/assets/images/Goods-left.png"
              class="arrow arrow-left"
              @click="leftclick"
            />
            <div>
              <ul ref="ulWidth">
                <li v-for="(pic, i) in filePath" :key="i">
                  <img
                    v-lazy="pic"
                    class="commodity-img-small"
                    :class="i === imgIndex ? 'onImages' : ''"
                    @click="selected(pic, i)"
                    :key="i"
                  />
                </li>
              </ul>
            </div>
            <img
              src="@/assets/images/Goods-right.png"
              class="arrow arrow-right"
              @click="rightclick"
            />
          </div>
        </div>
        <div class="right">
          <div class="title">
            <h2>
              商品名称：<span :title="CommodityDetails.commodityName">{{
                CommodityDetails.commodityName
              }}</span>
            </h2>
            <p>
              商品条形码：<span>{{ CommodityDetails.barcode }}</span>
            </p>
          </div>
          <div class="price-box">
            <p>
              <!-- 零售价： -->
              批发价：
              <span
                >￥<i>{{
                  preferentialTime
                    ? PriceDetails.preferentialRetailPrice
                    : PriceDetails.retailPrice | Price
                }}</i></span
              >
              <span v-if="preferentialTime" class="line_through"
                >[<i>￥{{ PriceDetails.retailPrice }}</i
                >]</span
              >
            </p>
            <!-- <p>
              批发价：
              <span
                >￥<i>{{
                  preferentialTime
                    ? PriceDetails.preferentialTradePrice
                    : PriceDetails.tradePrice | Price
                }}</i></span
              >
              <span v-if="preferentialTime" class="line_through"
                >[<i>￥{{ PriceDetails.tradePrice }}</i
                >]</span
              >
            </p> -->
            <p>
              （起订量：
              <span>≥1{{ CommodityDetails.prickle }}</span
              >）
              <!-- （起订量：
              <span
                >≥{{
                  preferentialTime
                    ? PriceDetails.pTradeMinimumQuantity
                    : PriceDetails.tradeMinimumQuantity
                }}{{ CommodityDetails.prickle }}</span
              >） -->
              <!-- <span>10／千克</span> -->
            </p>
          </div>
          <!-- <div class="content">
            <div>
  
              <p>
                生产厂商：<span :title="CommodityDetails.manufacturer">{{
                  CommodityDetails.manufacturer
                }}</span>
              </p>
              <p>
                品牌：　　<span>{{ CommodityDetails.brandName }}</span>
              </p>

              <p>
                储藏方法：<span>{{ CommodityDetails.conditions }}</span>
              </p>
              <p>
                包装形式：<span>{{ CommodityDetails.packagingUnit }}</span>
              </p>

              <p>
                种类：　　<span>{{ CommodityDetails.enView }}</span>
              </p>
            </div>
            <div>
              <p>
                厂址：　<span :title="CommodityDetails.address">{{
                  CommodityDetails.address
                }}</span>
              </p>
              <p>
                规格：　<span>{{ CommodityDetails.specsParameter }}</span>
              </p>
   
              <p>
                保质期：<span>{{ CommodityDetails.expirationDate }}天</span>
              </p>
              <p>
                产地：　<span
                  >{{ CommodityDetails.province
                  }}{{ CommodityDetails.city }}</span
                >
              </p>
            </div>
            <p class="td">
              商品特点：
              <span>{{
                CommodityDetails.characteristics === null
                  ? "无"
                  : CommodityDetails.characteristics
              }}</span>
            </p>
          </div> -->
          <div class="content">
            <div>
              <!-- CommodityDetails -->
              <p>
                生产厂商：<span :title="CommodityDetails.manufacturer">{{
                  CommodityDetails.manufacturer
                }}</span>
              </p>
              <p>
                归属品牌：<span>{{ CommodityDetails.brandName }}</span>
              </p>
              <!-- <p>主要成分：<span>——</span></p> -->

              <p>
                商品重量：<span>{{ CommodityDetails.weight }}千克</span>
              </p>
              <p>
                储藏方法：<span>{{ CommodityDetails.conditions }}</span>
              </p>
              <!-- <p>特产品类：<span>——</span></p> -->
              <p>
                保质期限：<span>{{ CommodityDetails.expirationDate }}天</span>
              </p>
            </div>
            <div>
              <p>
                厂商地址：<span :title="CommodityDetails.address">{{
                  CommodityDetails.address
                }}</span>
              </p>
              <p>
                商品产地：<span
                  >{{ CommodityDetails.province
                  }}{{ CommodityDetails.city }}</span
                >
              </p>
              <p>
                商品规格：<span>{{ CommodityDetails.specsParameter }}</span>
              </p>
              <!-- <p>等级：　<span>——</span></p> -->
              <p>
                计量单位：<span>{{ CommodityDetails.prickle }}</span>
              </p>
              <!-- <p>
                商品类目：<span>{{ CommodityDetails.enView }}</span>
              </p> -->
            </div>
            <p class="td">
              商品特点：
              <span :title="CommodityDetails.characteristics">{{
                CommodityDetails.characteristics === null
                  ? "无"
                  : CommodityDetails.characteristics
              }}</span>
            </p>
          </div>
          <!-- <div class="content">
            <div>
              <p>生产厂商：<span>北京正大果业有限公司</span></p>
              <p>保质期：<span>7 天</span></p>
              <p>储藏方法：<span>低温保鲜</span></p>
              <p>包装形式：<span>礼盒装</span></p>
              <p>特产品类：<span>长坝蜜柚</span></p>
            </div>
            <div>
              <p>厂址：<span>建国门内大街7号光华长安大厦15层</span></p>
              <p>品牌：<span>正大领鲜</span></p>
              <p>规格：<span>5.0kg</span></p>
              <p>产地：<span>广东韶关</span></p>
              <p>种类：<span>红心柚</span></p>
            </div>
          </div> -->
          <i class="dotted-line"></i>
          <div class="buy">
            <div>
              <el-input-number
                v-model="num"
                @change="handleChange"
                :min="1"
                :max="1000"
              ></el-input-number>
            </div>
            <p>累计销量：<span>9999+</span></p>
          </div>
          <el-dialog
            title="提示"
            :visible.sync="centerDialogVisible"
            width="30%"
            center
          >
            <span>需要登录</span>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="goLogin">前往登录页</el-button>
              <el-button @click="centerDialogVisible = false">取 消</el-button>
            </span>
          </el-dialog>
          <div class="add-to-shopping-cart" @click="AddShoppingCart">
            <svg-icon icon-class="ic-shoppingCart-b" />
            加入购物车
          </div>
          <!-- <div class="view-trace-tracing" @click="goTrackBack">
            <svg-icon icon-class="eye" />
            认证查询
          </div> -->
        </div>
      </div>
      <div class="the-introduction">
        <div class="title">商品详情</div>
        <div
          class="introduce-img"
          v-html="CommodityDetails.commodityContent"
        ></div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="tips" v-show="Tips">
        <div class="box">
          <div class="title">
            <span>添加成功</span>
            <button @click="Tips = false">×</button>
          </div>
          <div class="content">
            <p>
              <img src="@/assets/images/accept.png" alt="" />
              货品已添加至购物车！
            </p>
            <p>
              当前购物车共<span>{{ userSum }}</span
              >件商品
            </p>
            <p>
              <a href="#/ShoppingCart" target="_blank">去结算</a
              ><a @click="Tips = false">继续购物</a>
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import PicZoom from "vue-piczoom";
import Cookies from "js-cookie";
import { mapState } from "vuex";

export default {
  name: "goods-details",
  components: {
    Breadcrumb,
    PicZoom,
  },
  computed: {
    ...mapState("personalUser", ["userLogin", "userId", "userSum"]),
    ...mapState("user", ["brandId"]),
  },
  data() {
    return {
      num: 1,
      info: "",
      filePath: [],
      li: 0,
      ulW: 0, //ul的定位位置
      CommodityDetails: "", //商品详情
      PriceDetails: "", //商品价格详情
      preferentialTime: false, //是否优惠
      centerDialogVisible: false,
      imgIndex: 0,
      Tips: false,
    };
  },
  filters: {
    //处理函数
    Price(p) {
      if (p === undefined || p === null) {
        return "-";
      } else {
        if (String(Number(p)).indexOf(".")) {
          return p.toFixed(2);
        } else {
          return p;
        }
      }
    },
  },
  created() {
    this.getDetails();
    this.getPriceDetails();
  },
  mounted() {},
  methods: {
    //获取商品详情
    async getDetails() {
      const data = await this.$API.store.getCommodityDetails({
        object: {
          barcode: this.$route.params.barcode,
          enterpriseId: this.$route.query.enterpriseId,
        },
      });
      this.CommodityDetails = data.data.data;
      // this.filePath = data.data.data.filePath.split(",").map((pic, i) => {
      //   return i === 0 ? pic : "http://hwpicture.realbrand.net/" + pic;
      // });
      this.filePath = data.data.data.filePath.split(",");
      this.info = data.data.data.filePath.split(",")[0];
    },
    //获取商品价格详情
    async getPriceDetails() {
      const data = await this.$API.store.getCommodityPriceDetails({
        object: {
          barcode: this.$route.params.barcode,
          enterpriseId: this.$route.query.enterpriseId,
        },
      });
      this.PriceDetails = data.data.data;
           let now = new Date();
      let m = now.getTime();
      if (this.PriceDetails.preferentialStartTime == null) {
        this.preferentialTime = false;
        return;
      }
      if (
        this.PriceDetails.preferentialStartTime < m &&
        m < this.PriceDetails.preferentialEndTime
      ) {
        this.preferentialTime = true;
      } else {
        this.preferentialTime = false;
      }
    },

    //加购物车
    async addShoppingCart() {
      if (this.userLogin) {
        const data = await this.$API.personalUser
          .InsertCusOrderCart({
            object: {
              barcode: this.$route.params.barcode, // 条形码
              commId: Number(this.CommodityDetails.id), //商品id
              quantity: this.num, //购买数量
              storeId: this.$route.params.id, //店铺id
              userId: this.userId, //用户id
            },
          })
          .then(() => {
            this.Tips = true;
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("加入购物车失败");
          });
        this.$store.dispatch("personalUser/getShoppingCartSum");
      } else {
        if (this.brandId === this.$route.query.enterpriseId) {
          this.$message.error("不能添加自己的商品");
        } else {
          const data = await this.$API.order
            .addShoppingCart({
              object: {
                barcode: this.$route.params.barcode, // 条形码
                commId: this.CommodityDetails.id, //商品id
                quantity: this.num, //购买数量
                storeId: this.$route.params.id, //店铺id
              },
            })
            .then(() => {
              this.$message({
                message: "加入购物车成功",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("加入购物车失败");
            });
          this.$store.dispatch("user/getCartSum");
        }
      }
    },
    // 加减事件
    handleChange() {
      // console.log(this.num);
    },
    //放大镜
    selected(src, i) {
      this.info = src;
      this.imgIndex = i;
    },
    //小图左点击事件
    rightclick() {
      let Lilength = this.$refs.ulWidth.children.length;
      if (this.li < Lilength - 5) {
        this.li++;
        this.ulW += -76;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },
    //小图右点击事件
    leftclick() {
      if (this.li > 0) {
        this.li--;
        this.ulW += 76;
        this.$refs.ulWidth.style.left = this.ulW + "px";
      }
    },

    goTrackBack() {
      this.$router.push({
        name: "track-back",
        query: {
          id: this.$route.params.barcode,
          enterpriseId: this.$route.query.enterpriseId,
        },
      });
    },
    AddShoppingCart() {
      let token = Cookies.get("KAI_HUA_ADMIN_TOKEN");
      // let token = true;
      if (token || this.userLogin) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.addShoppingCart();
        }, 500);
      } else {
        this.centerDialogVisible = true;
      }
    },
    goLogin() {
      window.location.href = `${this.$URL}/#/login`;
    },
  },
};
</script>

<style lang="scss" scoped>
#goods-details {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .goods-details-con {
    display: flex;
    flex-direction: column;
    .top {
      width: 1200px;
      margin: 24px 0 40px 0;
      display: flex;
      .commodity-img-box {
        display: flex;
        flex-direction: column;
        ::v-deep .magnifier-box {
          border: 1px solid #eee;
          img {
            // width: auto;
            // height: auto;
            width: 100%;
            height: 100%;
          }
        }
        .commodity-img {
          width: 460px;
          height: 460px;
          img {
            width: 460px;
            height: 460px;
          }
        }

        .commodity-img-list {
          margin-top: 14px;
          width: 460px;
          position: relative;
          div {
            width: 384px;
            overflow: hidden;
            position: relative;
            height: 60px;
            margin-left: 30px;
            ul {
              width: 1040px;
              position: absolute;
              left: 0;
              transition: all 0.5s;
              li {
                float: left;
                margin-left: 16px;
                .commodity-img-small {
                  width: 60px;
                  height: 60px;
                  border: 2px solid #fff;
                  cursor: pointer;
                }
                .onImages {
                  border: 2px solid var(--subjectColor);
                }
              }
            }
          }
          .arrow {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 15px;
            cursor: pointer;
          }
          .arrow-left {
            left: 0;
          }
          .arrow-right {
            right: 0;
          }
        }
      }
      .right {
        // width: 560px;
        height: 460px;
        margin-left: 24px;
        position: relative;
        .title {
          display: flex;
          line-height: 26px;
          color: #333333;
          justify-content: space-between;
          h2 {
            font-size: 20px;
            color: #888888;
            width: 520px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              color: #333333;
            }
          }
          p {
            font-size: 14px;
            color: #999999;
            span {
              color: var(--subjectColor);
            }
          }
        }
        .price-box {
          width: 716px;
          height: 68px;
          margin: 16px 0;
          display: flex;
          align-items: center;
          background: url("../../assets/images/price-bg.png");
          p {
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            height: 20px;
            span:first-child {
              color: #ec382f;
              i {
                font-size: 22px;
              }
            }
            .line_through {
              margin-left: 4px;
              i {
                text-decoration: line-through;
              }
            }
          }
          p:first-child {
            width: 342px;
            margin-left: 8px;
          }
          p:nth-child(2) {
            margin-right: 2px;
          }
          p:last-child {
            line-height: 16px;
            height: 16px;
            span {
              color: var(--subjectColor);
            }
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .td {
            font-size: 14px;
            height: 20px;
            color: #888888;
            display: flex;
            line-height: 20px;
            margin-bottom: 10px;
            span {
              width: 556px;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          div {
            flex: 1;
            p {
              font-size: 14px;
              height: 20px;
              color: #888888;
              display: flex;
              line-height: 20px;
              margin-bottom: 10px;
              span {
                width: 210px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .dotted-line {
          width: 100%;
          border-top: 1px dashed #ccc;
          display: block;
          margin: 6px 0 20px 0;
        }
        .buy {
          height: 36px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            display: flex;
            align-items: center;
            .el-input-number {
              line-height: 36px;
              width: 160px;
              height: 36px;
              margin-right: 20px;
              ::v-deep.el-input-number__decrease {
                width: 36px;
                height: 34px;
                top: 2px;
                line-height: 34px;
                color: #333;
                .el-icon-minus:before {
                  font-weight: 900;
                }
              }
              .el-input-number__decrease:hover {
                color: #333;
              }
              ::v-deep.el-input-number__increase {
                width: 36px;
                height: 34px;
                top: 2px;
                line-height: 34px;
                color: #333;
                .el-icon-plus:before {
                  font-weight: 900;
                }
              }
              .el-input-number__increase:hover {
                color: #333;
              }
              ::v-deep.el-input__inner {
                border-radius: 0;
                height: 36px;
                border-color: #ddd;
              }
              ::v-deep.el-input__inner:focus {
                border-color: none;
              }
            }
            p:last-child {
              color: #999999;
              i {
                color: var(--subjectColor);
                margin-right: 4px;
              }
            }
          }

          p:last-child {
            color: #999999;
            span {
              color: var(--subjectColor);
            }
          }
        }
        ::v-deep .el-dialog__wrapper {
          .el-dialog__body {
            text-align: center;
            font-size: 16px;
          }
          .el-dialog__footer {
            .el-button--primary {
              background: var(--subjectColor);
              border: var(--subjectColor) 1px solid;
            }
            .el-button--default:hover {
              color: var(--subjectColor);
              background: rgba($color: #fff, $alpha: 0.2);
              border: rgba($color: var(--subjectColor), $alpha: 0.2) 1px solid;
            }
          }
        }
        .add-to-shopping-cart {
          width: 148px;
          height: 42px;
          background: var(--subjectColor);
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 42px;
          position: absolute;
          bottom: 0px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
        .view-trace-tracing {
          width: 148px;
          height: 42px;
          background: rgba(0, 196, 92, 0.05);
          border-radius: 4px;
          font-size: 16px;
          color: var(--subjectColor);
          text-align: center;
          border: 1px solid var(--subjectColor);
          line-height: 40px;
          position: absolute;
          left: 160px;
          bottom: 0px;
          cursor: pointer;
          .svg-icon {
            font-size: 16px;
          }
        }
      }
    }
    .the-introduction {
      width: 1200px;
      border: 1px solid #ddd;
      margin-bottom: 40px;
      .title {
        height: 40px;
        background: #f7f7f7;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        color: #333333;
        padding-left: 20px;
        border-bottom: 1px solid #ddd;
      }
      ::v-deep .introduce-img {
        padding: 30px 180px 32px;
        img {
          width: 100%;
          vertical-align: bottom;
        }
      }
    }
  }
  .tips {
    background: rgba(0, 0, 0, 0.39);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 10000;
    .box {
      width: 560px;
      height: 191px;
      background: #fff;
      position: relative;
      left: 50%;
      top: 50%;
      margin-left: -280px;
      margin-top: -95px;

      .title {
        height: 46px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        span {
          font-weight: bold;
          font-size: 16px;
        }
        button {
          font-size: 30px;
          border: none;
          background: #fff;
          color: #666666;
          cursor: pointer;
        }
      }
      .content {
        padding: 33px 96px;
        p:nth-child(1) {
          position: relative;
          height: 20px;
          font-size: 14px;
          font-weight: 600;
          color: #333333;
          img {
            position: absolute;
            left: -36px;
            top: -3px;
          }
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 16px;
          color: #666666;
          margin: 8px 0 12px 0;
          span {
            color: var(--subjectColor);
          }
        }
        p a {
          font-size: 12px;
          line-height: 16px;
          color: var(--subjectColor);
          margin-right: 20px;
          cursor: pointer;
        }
        p a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
<style>
.mouse-cover-canvas {
  box-sizing: border-box;
  background: #fff;
  top: 388px !important;
}
</style>